var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-col gap-6"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "BPJS TK",
      "placeholder": "Employee's BPJS TK",
      "borderEnabled": true,
      "disabled": _vm.props2 === 'Detail'
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "BPJS Kesehatan",
      "placeholder": "Employee's BPJS Kesehatan",
      "borderEnabled": true,
      "disabled": _vm.props2 === 'Detail'
    },
    model: {
      value: _vm.sotherEmail,
      callback: function callback($$v) {
        _vm.sotherEmail = $$v;
      },
      expression: "sotherEmail"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }